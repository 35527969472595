import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AuthContext } from "./context/auth-context";
import { useAuth } from "./hooks/auth-hook";
import ContactUs from "./components/ContactUs/ContactUs";
import { Legal } from "./components/Legal/Legal";
import { Developers } from "./components/Developers/Developers";
import { AdminPanel } from "./components/AdminPanel/AdminPanel";
import { MainNavigation } from "./components/Navigation/MainNavigation/MainNavigation";
import { Flex, Spinner } from "@chakra-ui/react";
import { populateTreesAtLocalStorage } from "components/Map/mapService";

const UserTrees = React.lazy(() =>
  import("./components/Trees/UserTrees/UserTrees")
);
const MapDeckGl = React.lazy(() => import("./components/Map/MapDeckGl"));
const Auth = React.lazy(() => import("./components/Auth/Auth"));
const LandingPage = React.lazy(() =>
  import("./components/LandingPage/LandingPage")
);
const Settings = React.lazy(() => import("./components/Settings/Settings"));
const ResetPassword = React.lazy(() =>
  import("./components/ResetPassword/ResetPassword")
);

const App = () => {
  const { token, login, logout, userId, roles, email, isLinkedAccount } =
    useAuth();

  useEffect(() => {
    if (userId && token) {
      populateTreesAtLocalStorage(token);
    }
  }, [token, userId]);

  let routes;

  if (token) {
    routes = (
      <Routes>
        <Route path="/trees" exact element={<UserTrees />} />
        <Route path="/map" exact element={<MapDeckGl />} />
        <Route path="/settings" exact element={<Settings />} />
        <Route path="/legal" exact element={<Legal />} />
        <Route path="/contact" exact element={<ContactUs />} />
        <Route path="/developers" exact element={<Developers />} />
        <Route path="/reset-password" exact element={<ResetPassword />} />
        {roles && roles.includes("admin") && (
          <Route path="/admin-panel" exact element={<AdminPanel />} />
        )}
        <Route path="*" element={<LandingPage />} />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route path="/reset-password" exact element={<ResetPassword />} />
        <Route path="/" exact element={<LandingPage />} />
        <Route path="/auth" exact element={<Auth />} />
        <Route path="/legal" exact element={<Legal />} />
        <Route path="/contact" exact element={<ContactUs />} />
        <Route path="/developers" exact element={<Developers />} />
        <Route path="*" element={<LandingPage />} />
      </Routes>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
        roles: roles,
        email: email,
        isLinkedAccount,
      }}
    >
      <Router>
        <MainNavigation />
        <Flex
          id="app-body"
          maxW="1200px"
          w="100%"
          pos="relative"
          flex="1"
          direction="column"
          alignSelf="center"
        >
          <Suspense
            fallback={
              <Flex justify="center" align="center" mt={20}>
                <Spinner
                  speed="0.6s"
                  thickness="4px"
                  colorScheme="green"
                  size="xl"
                />
              </Flex>
            }
          >
            {routes}
          </Suspense>
        </Flex>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
