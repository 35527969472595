import { fetchTrees, getTreesVersion } from "services/api";

export function storeTreeDataToLocalStorage(data, version) {
  localStorage.setItem("treeData", JSON.stringify(data));
  localStorage.setItem("treeDataVersion", version);
}

export function getTreeDataFromLocalStorage() {
  return JSON.parse(localStorage.getItem("treeData"));
}

export function treeDataMapper(tree) {
  return {
    type: "Feature",
    properties: {
      id: tree.t,
      date: tree.d,
      owner: [],
    },
    geometry: {
      type: "Point",
      coordinates: [tree.x, tree.y],
    },
  };
}

export async function populateTreesAtLocalStorage(token) {
  try {
    const treesVersion = await getTreesVersion(token);

    if (
      localStorage.getItem("treeDataVersion") === treesVersion &&
      localStorage.getItem("treeData")
    )
      return;

    const fetchedTrees = await fetchTrees(token).then(
      (response) => response.data.trees
    );
    storeTreeDataToLocalStorage(fetchedTrees, treesVersion);
  } catch (error) {
    console.error(error);
  }
}

export const treeFilterMapper = {
  1: "all-trees",
  2: "adopted-trees",
  3: "available-trees",
};
