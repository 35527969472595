import queryString from "query-string";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

function sendRequest(
  url,
  {
    method = "GET",
    useCredentials = false,
    body,
    headers = {},
    queryParams = {},
  }
) {
  const options = {
    method,
    headers: new Headers({ "content-type": "application/json", ...headers }),
    body: body ? JSON.stringify(body) : null,
  };
  if (useCredentials) options.credentials = "include";
  if (queryParams) {
    url = `${url}?${queryString.stringify(queryParams)}`;
  }

  return fetch(url, options).then((res) => {
    if (res.ok) {
      const contentLength = res.headers.get("Content-Length");
      const contentType = res.headers.get("Content-Type");
      if (
        contentLength !== "0" &&
        contentType &&
        contentType.indexOf("application/json") !== -1
      ) {
        return res.json();
      } else {
        return res.text();
      }
    } else {
      return res.json().then(function (json) {
        return Promise.reject({
          status: res.status,
          ok: false,
          message: json.message,
          body: json,
        });
      });
    }
  });
}

export function postResetPassword(email) {
  return sendRequest(BACKEND_URL + "/users/reset-password", {
    method: "POST",
    body: {
      email,
    },
  });
}

export function adopt(token, treeId) {
  return sendRequest(BACKEND_URL + `/trees/${treeId}/adopt`, {
    method: "PATCH",
    headers: {
      Authorization: "JWT " + token,
    },
  });
}

export function water(token, treeId) {
  return sendRequest(BACKEND_URL + `/trees/${treeId}/water`, {
    method: "PATCH",
    headers: {
      Authorization: "JWT " + token,
    },
  });
}

export function renameTree(token, treeId, name) {
  return sendRequest(BACKEND_URL + `/trees/${treeId}/rename`, {
    method: "PATCH",
    body: {
      name,
    },
    headers: {
      Authorization: "JWT " + token,
    },
  });
}

export function deleteTree(token, treeId) {
  return sendRequest(BACKEND_URL + `/trees/${treeId}/abandon`, {
    method: "PATCH",
    headers: {
      Authorization: "JWT " + token,
    },
  });
}

export function zipCodeSearch(token, zip) {
  return sendRequest(BACKEND_URL + `/trees/${zip}/getTreeByZip`, {
    headers: {
      Authorization: "JWT " + token,
    },
  });
}

export function fetchTrees(token) {
  return sendRequest(BACKEND_URL + `/trees`, {
    headers: {
      Authorization: "JWT " + token,
    },
  });
}

export function fetchTreesOfUser(token) {
  return sendRequest(BACKEND_URL + `/trees/user`, {
    headers: {
      Authorization: "JWT " + token,
    },
  }).then((response) => response.data.trees);
}

export function fetchUserTreeData(token) {
  return sendRequest(BACKEND_URL + `/trees/getUserData`, {
    headers: {
      Authorization: "JWT " + token,
    },
  }).then((response) => response.data);
}

export function getTreeInfo(token, treeId) {
  return sendRequest(BACKEND_URL + `/trees/${treeId}/getTreeInfo`, {
    headers: {
      Authorization: "JWT " + token,
    },
  }).then((response) => response.data);
}

export function getGlobalFilter(token) {
  return sendRequest(BACKEND_URL + `/users/get-global-filter`, {
    headers: {
      Authorization: "JWT " + token,
    },
  }).then((response) => response.data.filterValue);
}

export function setGlobalFilter(token, userId, filterValue) {
  return sendRequest(BACKEND_URL + `/users/set-global-filter`, {
    method: "PATCH",
    body: {
      uid: userId,
      filterValue,
    },
    headers: {
      Authorization: "JWT " + token,
    },
  });
}

export function getTreeNickName(token, treeId) {
  return sendRequest(BACKEND_URL + `/trees/${treeId}/treenickname`, {
    headers: {
      Authorization: "JWT " + token,
    },
  }).then((response) => response.data.userTree.tree_nickname);
}

export function deleteUser(token, userId) {
  return sendRequest(BACKEND_URL + `/users/delete/${userId}`, {
    method: "DELETE",
    body: {
      uid: userId,
    },
    headers: {
      Authorization: "JWT " + token,
    },
  });
}

export function changeEmail(token, userId, email) {
  return sendRequest(BACKEND_URL + `/users/change-email`, {
    method: "PATCH",
    body: {
      uid: userId,
      email,
    },
    headers: {
      Authorization: "JWT " + token,
    },
  });
}

export function requestResetPassword(token, email) {
  return sendRequest(BACKEND_URL + `/users/reset-password`, {
    method: "POST",
    body: {
      email,
    },
    headers: {
      Authorization: "JWT " + token,
    },
  });
}

export function changePassword(password, email, hid) {
  return sendRequest(BACKEND_URL + `/users/password/change`, {
    method: "POST",
    body: {
      password,
      email,
      hid,
    },
  });
}

export function verificationProcess(email, entry) {
  return sendRequest(BACKEND_URL + `/users/verification-process`, {
    method: "PATCH",
    body: {
      email,
      entry,
    },
  });
}

export function login(email, password) {
  return sendRequest(BACKEND_URL + "/users/login", {
    method: "POST",
    body: {
      email,
      password,
    },
  });
}

export function googleLogin(googleData) {
  return sendRequest(BACKEND_URL + "/auth/google-login", {
    method: "POST",
    body: {
      googleData,
    },
  });
}

export function facebookLogin(facebookData) {
  return sendRequest(BACKEND_URL + "/auth/facebook-login", {
    method: "POST",
    body: {
      facebookData,
    },
  });
}

export function signup(email, password) {
  return sendRequest(BACKEND_URL + "/users/signup", {
    method: "POST",
    body: {
      email,
      password,
    },
  });
}

export function verifyEmail(email) {
  return sendRequest(BACKEND_URL + "/users/verify-email", {
    method: "POST",
    body: {
      email,
    },
  });
}

export function getTreesVersion(token) {
  return sendRequest(BACKEND_URL + `/trees/version`, {
    headers: {
      Authorization: "JWT " + token,
    },
  }).then((response) => response.data);
}
