import React from "react";
import "./Developers.css";
import { Col, Container, Row } from "react-grid-system";
import Card from "../CustomUIElements/Card/Card";
import { greekLabels } from "../../util/language";

export function Developers() {
  return (
    <React.Fragment>
      <Container className="developers">
        <h1>
          {greekLabels.DEVELOPERS} <i className="bi bi-file-person"></i>
        </h1>
        <p style={{ textAlign: "left" }}>
          Η ψηφιακή εφαρμογή για κινητά και υπολογιστές «ΠοτιΖΩ» αναπτύχθηκε για
          το Δήμο Θεσσαλονίκης από ομάδες φοιτητών του τμήματος Πληροφορικής του
          CITY College, University of York Europe Campus στη Θεσσαλονίκη.
        </p>
        <p style={{ textAlign: "left" }}>
          Αποτελεί μέρος του Industrial Project των φοιτητών, ενός υποχρεωτικού
          μαθήματος που εντάσσεται στο πρόγραμμα σπουδών του Τμήματος της
          Πληροφορικής του CITY College. Οι φοιτητές εργάζονται ομαδικά σε
          αληθινές συνθήκες εργασίας, αναπτύσουν νέες δεξιότητες και συμμετέχουν
          στην ανάπτυξη ψηφιακών εργαλείων που βοηθούν την κοινωνία.
        </p>
        <p style={{ textAlign: "left" }}>
          Τα άτομα που εργάστηκαν για το «ΠοτιΖΩ» είναι οι :
        </p>
        <Row>
          <Col sm={12} md={4} className="column">
            <h3>Ομάδα 1</h3>
            <Card>
              <span>Antonios Antoniadis</span>
            </Card>
            <Card>
              <span>Evangelos Barmpas</span>
            </Card>
            <a
              className="link-style"
              href="https://www.linkedin.com/mwlite/in/lorikkorca"
            >
              <Card>
                <span> Lorik Korca</span>
              </Card>
            </a>
            <Card>
              <span>Rei Manushi </span>
            </Card>
            <Card>
              <span> Vijon Baraku</span>
            </Card>
          </Col>
          <Col sm={12} md={8} className="column link-style">
            <h3>Ομάδα 2</h3>
            <a
              href="https://www.linkedin.com/in/nikos-lintas-computer-scientist/"
              className="link-style"
            >
              <Card>
                <span>Nikolaos Lintas</span>
              </Card>
            </a>
            <a
              href="https://www.linkedin.com/in/phillipos-andrianos-k-968454119/"
              className="link-style"
            >
              <Card>
                <span>Philippos Andrianos Kalatzis</span>
              </Card>
            </a>
            <a
              href="https://www.linkedin.com/in/giorgos-tamvakas-524253213/"
              className="link-style"
            >
              <Card>
                <span>Georgios Tamvakas</span>
              </Card>
            </a>
            <a
              href="https://www.linkedin.com/in/aggelogiannos/"
              className="link-style"
            >
              <Card>
                <span>Ioannis Angelos Chaidemenos</span>
              </Card>
            </a>
          </Col>
          <Col sm={12} md={4} className="column">
            <h3>Ομάδα 3</h3>
            <Card>
              <span>Tron Baraku</span>
            </Card>
            <Card>
              <span>Chase Burton Taylor</span>
            </Card>
            <Card>
              <span>Marino Osmanllari</span>
            </Card>
            <Card>
              <span>Yusuf Demirhan</span>
            </Card>
          </Col>
          <Col sm={12} md={4} className="column">
            <h3>Ομάδα 4</h3>
            <a
              href="https://www.linkedin.com/in/aleksandra-pavlovic-8264ba195"
              className="link-style"
            >
              <Card>
                <span>Aleksandra Pavlovic</span>
              </Card>
            </a>
            <a
              href="https://gr.linkedin.com/in/alpournaras"
              className="link-style"
            >
              <Card>
                <span>Alexandros Pournaras</span>
              </Card>
            </a>
            <a
              href="https://gr.linkedin.com/in/eleni-kokonesi-3808a7154"
              className="link-style"
            >
              <Card>
                <span>Eleni Kokonesi</span>
              </Card>
            </a>
            <a
              href="https://www.linkedin.com/in/grigoris-papapostolou-b156bb156"
              className="link-style"
            >
              <Card>
                <span>Grigorios Papapostolou</span>
              </Card>
            </a>
          </Col>
          <Col sm={12} md={4} className="column">
            <h3 style={{ visibility: "hidden" }}>Ομάδα 4</h3>
            <a
              href="https://gr.linkedin.com/in/elias-moutzouris-03a6862a"
              className="link-style"
            >
              <Card>
                <span>Ilias Moutzouris</span>
              </Card>
            </a>
            <a
              href="https://www.linkedin.com/in/nitahoxha1/"
              className="link-style"
            >
              <Card>
                <span>Nita Hoxha</span>
              </Card>
            </a>
            <a
              href="http://linkedin.com/in/vjosashalasahiti"
              className="link-style"
            >
              <Card>
                <span>Vjosa Shala Sahiti</span>
              </Card>
            </a>
          </Col>
        </Row>
        <div className="invent">
          <a href="https://invent.york.citycollege.eu/">
            Created as part of Invent, City College
            <i
              style={{ color: "green", fontSize: "20px" }}
              className="bi bi-lightbulb"
            ></i>
          </a>
        </div>
      </Container>
    </React.Fragment>
  );
}
