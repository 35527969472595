import React from "react";
import { createRoot } from "react-dom/client";

import "modern-normalize/modern-normalize.css";
import "./index.css";
import App from "./App";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { ChakraProvider, Flex, theme } from "@chakra-ui/react";

// Replaced with React 18 compatible version
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ChakraProvider theme={theme}>
    <Flex minHeight="100vh" direction="column">
      <App />
    </Flex>
  </ChakraProvider>
);

serviceWorkerRegistration.unregister();
