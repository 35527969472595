import React from "react";
import "./ContactUs.css";
import { Container } from "react-grid-system";
import { greekLabels } from "../../util/language";
import { Box } from "@chakra-ui/react";

const cityEmail = `mailto:${process.env.REACT_APP_CITY_EMAIL}`;
const munEmail = `mailto:${process.env.REACT_APP_MUN_EMAIL}`;

const ContactUs = () => (
  <div className={"contact-us main-text"}>
    <Container>
      {/*Information Area*/}
      <h1>
        {greekLabels.CONTACT_US} <i className="bi bi-telephone-fill"></i>
      </h1>
      <p className="text">{greekLabels.INTRODUCTION_1}</p>
      <p className="text">{greekLabels.INTRODUCTION_2}</p>
      <h2>{greekLabels.TECHNICAL_SUPPORT_TITLE}</h2>
      <p className="text">{greekLabels.TECHNICAL_SUPPORT_TEXT}</p>
      <p className="text">
        <b>{greekLabels.CITY_COLLEGE_COMMUNICATION_TEXT}</b>
      </p>

      {/* TODO: Replace old Paper component */}
      <Box>
        <ul className="list">
          <li>
            <i className="bi bi-envelope-fill"></i> Email:
            <a href={cityEmail}>{cityEmail}</a>
            {process.env.REACT_APP_CITY_EMAIL}
          </li>
        </ul>
      </Box>

      {/*Information Area*/}
      <p className="text">
        <b>{greekLabels.THESSALONIKI_MUNICIPALITY_COMMUNICATION_TEXT}</b>
      </p>
      {/* Cards */}
      <Box>
        <ul className="list">
          <li>
            <i className="bi bi-envelope-fill"></i> Email:
            <a href={munEmail}>
              {process.env.REACT_APP_MUN_EMAIL === "email_here"
                ? "No Email Provided Yet"
                : process.env.REACT_APP_MUN_EMAIL}
            </a>
          </li>
        </ul>
      </Box>
    </Container>
  </div>
);
ContactUs.propTypes = {};
ContactUs.defaultProps = {};

export default ContactUs;
