import React from "react";
import { Col, Container, Row } from "react-grid-system";
import "./AdminPanel.css";
import { Link } from "react-router-dom";
import { greekLabels } from "../../util/language";

export function AdminPanel() {
  return (
    <React.Fragment>
      <Container className="admin-panel">
        <Row>
          <Col md={12}>
            <h1>
              {greekLabels.ADMIN_PANEL} <i className="bi-shield-shaded"></i>
            </h1>
          </Col>
          <Col md={12} className="admin-col">
            <div className="admin-links">
              <li>
                <Link to="/reports">{greekLabels.REPORTS} </Link>
              </li>
              <li>
                <Link to="/accounts-manager">
                  {greekLabels.USER_MANAGEMENT}
                </Link>
              </li>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
