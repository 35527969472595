import React, { useContext } from "react";
import { AuthContext } from "../../../context/auth-context";
import { greekLabels } from "../../../util/language";
import { NavLink, useMatch } from "react-router-dom";
import logo from "../../../assets/images/logo-green-bg.png";

import "./DesktopNavigation.css";

export function DesktopNavigation() {
  const auth = useContext(AuthContext);

  const username = auth.email
    ? auth.email.substring(0, auth.email.indexOf("@"))
    : "";

  const matchTrees = useMatch("/trees");
  const matchMap = useMatch("/map");

  return (
    <>
      <div className="logo-container">
        <NavLink to={"/"} className="nav-link">
          <img className="logo" alt="logo" src={logo} />
        </NavLink>
      </div>

      {auth.isLoggedIn && (
        <nav className="menu-nav">
          <ul id="menu-links" className="menu-links">
            <li className={`menu-item ${matchTrees ? "active-menu-link" : ""}`}>
              <NavLink to={"/trees"} className="menu-link">
                {greekLabels.MY_TREES_LOWER}
              </NavLink>
            </li>
            <li className={`menu-item ${matchMap ? "active-menu-link" : ""}`}>
              <NavLink to={"/map"} className="menu-link">
                {greekLabels.TREE_MAP}
              </NavLink>
            </li>
          </ul>
        </nav>
      )}

      <div className="header-login-register">
        {auth.isLoggedIn ? (
          <>
            <NavLink
              as="div"
              to={"/settings"}
              className="hoverable logged-in-user-container"
            >
              <div className="username-container">{username}</div>
            </NavLink>
            <div className="hoverable icon-container" onClick={auth.logout}>
              <i className="bi bi-box-arrow-right" id="sign-out-icon"></i>
            </div>
          </>
        ) : (
          <NavLink to={"/auth"}>
            <button type="button" className="header-login-register-button">
              {greekLabels.LOGIN}
            </button>
          </NavLink>
        )}
      </div>
    </>
  );
}
