import React from "react";
import { MobileNavigation } from "../MobileNavigation/MobileNavigation";
import { DesktopNavigation } from "../DesktopNavigation/DesktopNavigation";
import { Box, Flex, useMediaQuery } from "@chakra-ui/react";

export function MainNavigation() {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  return (
    <Box id="app-nav" w="100%" pos="sticky" top="0" zIndex={1} bg="green.500">
      <Flex
        h={16}
        maxW="1200px"
        mx="auto"
        display="flex"
        align="center"
        justify="space-between"
      >
        {isLargerThan800 ? <DesktopNavigation /> : <MobileNavigation />}
      </Flex>
    </Box>
  );
}
